'use client';

import { cn } from '../../utils';
import { ChangeEventHandler, FC } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { FilePlusIcon } from '../Icons';

export type DropzoneProps = {
  dropzoneOptions: DropzoneOptions;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

export const Dropzone: FC<DropzoneProps> = ({ className, dropzoneOptions, onChange }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone(dropzoneOptions);

  const getDropzoneMessage = () => {
    const fileDescriptor = dropzoneOptions?.multiple === false ? 'file' : 'file(s)';

    if (isDragActive) {
      return `Drop ${fileDescriptor} here`;
    }

    return `Drag and drop ${fileDescriptor} here, or click to select ${fileDescriptor}`;
  };

  return (
    <div
      className={cn(
        'w-full rounded-md border border-dashed border-primary p-6',
        {
          'bg-secondary': isDragActive,
        },
        className,
      )}
      {...getRootProps()}
    >
      <div className='flex flex-col items-center justify-center'>
        <input {...getInputProps({ ...(onChange ? { onChange } : {}) })} />
        <FilePlusIcon className='h-12 w-12' />

        <p className='pt-2'>{getDropzoneMessage()}</p>
      </div>
    </div>
  );
};
