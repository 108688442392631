import * as React from 'react';
import { ChevronLeftIcon, ChevronRightIcon, DotsHorizontalIcon } from '@radix-ui/react-icons';

import { cn } from '../../utils/shad-utils';
import { Button, ButtonProps, buttonVariants } from './button';
import Link from 'next/link';

const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav role='navigation' aria-label='pagination' className={cn('flex w-full justify-center', className)} {...props} />
);
Pagination.displayName = 'Pagination';

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(
  ({ className, ...props }, ref) => (
    <ul ref={ref} className={cn('flex flex-row items-center gap-1', className)} {...props} />
  ),
);
PaginationContent.displayName = 'PaginationContent';

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn('', className)} {...props} />
));
PaginationItem.displayName = 'PaginationItem';

type PaginationLinkProps = {
  isActive?: boolean;
} & Pick<ButtonProps, 'size'> &
  React.ComponentProps<typeof Link>;

const PaginationLink = ({ className, isActive, size = 'icon', ...props }: PaginationLinkProps) => (
  <Link
    aria-current={isActive ? 'page' : undefined}
    className={cn(
      buttonVariants({
        variant: isActive ? 'outline' : 'ghost',
        size,
      }),
      className,
    )}
    {...props}
  />
);
PaginationLink.displayName = 'PaginationLink';

const PaginationPrevious = ({ className, ...props }: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink aria-label='Go to previous page' size='default' className={cn('px-2.5', className)} {...props}>
    <ChevronLeftIcon className='h-4 w-4' />
  </PaginationLink>
);
PaginationPrevious.displayName = 'PaginationPrevious';

const PaginationPreviousDisabled = ({ className, children, ...props }: React.ComponentProps<typeof Button>) => (
  <Button
    size='tight'
    variant='ghost'
    className={cn('gap-1 pr-2.5', className)}
    disabled={true}
    aria-label='Go to previous page disabled'
    {...props}
  >
    {children ? children : <ChevronLeftIcon className='h-4 w-4' />}
  </Button>
);
PaginationPreviousDisabled.displayName = 'PaginationPreviousDisabled';

const PaginationNext = ({ className, ...props }: React.ComponentProps<typeof PaginationLink>) => (
  <PaginationLink aria-label='Go to next page' size='default' className={cn('px-2.5', className)} {...props}>
    <ChevronRightIcon className='h-4 w-4' />
  </PaginationLink>
);
PaginationNext.displayName = 'PaginationNext';

const PaginationNextDisabled = ({ className, children, ...props }: React.ComponentProps<typeof Button>) => (
  <Button
    size='default'
    variant='ghost'
    className={cn('gap-1 pr-2.5', className)}
    disabled={true}
    aria-label='Go to next page disabled'
    {...props}
  >
    {children ? children : <ChevronRightIcon className='h-4 w-4' />}
  </Button>
);
PaginationNextDisabled.displayName = 'PaginationNextDisabled';

const PaginationEllipsis = ({ className, ...props }: React.ComponentProps<'span'>) => (
  <span aria-hidden className={cn('flex h-9 w-9 items-center justify-center', className)} {...props}>
    <DotsHorizontalIcon className='h-4 w-4' />
    <span className='sr-only'>More pages</span>
  </span>
);
PaginationEllipsis.displayName = 'PaginationEllipsis';

export {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationNextDisabled,
  PaginationPrevious,
  PaginationPreviousDisabled,
};
