'use client';

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationNextDisabled,
  PaginationPrevious,
  PaginationPreviousDisabled,
} from '../ui/pagination';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { useCallback } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { Route } from 'next';

interface DataTablePaginationProps {
  total: number;
  limit: number;
  offset: number;
}

const getPaginationPageNums = ({
  currentPage,
  pageCount,
}: {
  currentPage: number;
  pageCount: number;
}): Array<number | string> => {
  const pageNums: Array<number | string> = [];

  for (let i = 1; i <= pageCount; i++) {
    pageNums.push(i);
  }

  if (pageCount < 7) {
    return pageNums;
  }

  const startElements: Array<number | string> = [];
  const centralElements: Array<number | string> = [];
  const endElements: Array<number | string> = [];

  if (currentPage < 5) {
    startElements.push(...pageNums.slice(0, 5));
  }

  if (currentPage >= 5) {
    startElements.push(1, 'start_ellipse');
  }

  if (currentPage >= 5 && pageCount - currentPage > 3) {
    centralElements.push(currentPage - 1, currentPage, currentPage + 1);
  }

  if (pageCount - currentPage < 4) {
    endElements.push(...pageNums.slice(-5));
  }

  if (pageCount - currentPage >= 4) {
    endElements.push('end_ellipse', ...pageNums.slice(-1));
  }

  return [...startElements, ...centralElements, ...endElements];
};

const PaginationElements = ({
  currentPage,
  pageCount,
  pageSize,
  getPaginatedHref,
}: {
  currentPage: number;
  pageCount: number;
  pageSize: number;
  getPaginatedHref: (name: string, value: string) => Route<string>;
}) => {
  const pageNums = getPaginationPageNums({ currentPage, pageCount });

  return (
    <>
      {pageNums.map((pageNum) => (
        <PaginationItem key={`pagination_page_${pageNum}`}>
          {typeof pageNum === 'string' ? (
            <PaginationEllipsis key={pageNum} />
          ) : (
            <PaginationLink
              key={`pagination_link_${pageNum}`}
              href={getPaginatedHref('offset', `${(pageNum - 1) * pageSize}`)}
              isActive={pageNum === currentPage}
            >
              {pageNum}
            </PaginationLink>
          )}
        </PaginationItem>
      ))}
    </>
  );
};

const PreviousButton = ({
  offset,
  pageSize,
  getPaginatedHref,
}: {
  offset: number;
  pageSize: number;
  getPaginatedHref: (name: string, value: string) => Route<string>;
}) => {
  if (offset === 0) {
    return (
      <PaginationItem className='hidden hover:cursor-not-allowed md:block'>
        <PaginationPreviousDisabled />
      </PaginationItem>
    );
  }

  return (
    <PaginationItem className='hidden md:block'>
      <PaginationPrevious href={getPaginatedHref('offset', `${offset - pageSize}`)} aria-disabled={true} />
    </PaginationItem>
  );
};

const NextButton = ({
  total,
  offset,
  pageSize,
  getPaginatedHref,
}: {
  total: number;
  offset: number;
  pageSize: number;
  getPaginatedHref: (name: string, value: string) => Route<string>;
}) => {
  const nextOffset = offset + pageSize;

  if (nextOffset >= total) {
    return (
      <PaginationItem className='hidden hover:cursor-not-allowed md:block'>
        <PaginationNextDisabled />
      </PaginationItem>
    );
  }

  return (
    <PaginationItem className='hidden md:block'>
      <PaginationNext href={getPaginatedHref('offset', nextOffset.toString())} aria-disabled={true} />
    </PaginationItem>
  );
};

export function DataTablePagination({ total, limit, offset }: DataTablePaginationProps) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const getUpdatedQueryString = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams(searchParams ?? {});
      params.set(name, value);

      return params.toString();
    },
    [searchParams],
  );

  const getPaginatedHref = useCallback(
    (name: string, value: string): Route<string> => {
      return `${pathname}?${getUpdatedQueryString(name, value)}` as Route;
    },
    [getUpdatedQueryString],
  );

  const updateLimit = useCallback(
    (value: string) => {
      const params = new URLSearchParams(searchParams ?? {});
      params.set('limit', value);

      const newLimit = Number.parseInt(value) as number;

      const currentOffset = Number.parseInt(searchParams?.get('offset') ?? '0');

      const newOffset = total - newLimit;

      if (currentOffset > newOffset && newOffset >= 0) {
        params.set('offset', `${newOffset}`);
      }

      router.push(`${pathname}?${params.toString()}` as Route);
      router.refresh();
    },
    [searchParams, getPaginatedHref],
  );

  const pageCount = Math.ceil(total / limit) || 1;
  const currentPage = Math.ceil(offset / limit) + 1;

  return (
    <div className='grid grid-cols-2 items-start justify-start gap-2 py-2 sm:flex sm:flex-row sm:items-center sm:justify-between'>
      <div className='flex flex-1 items-center space-x-6 pl-2 lg:space-x-8 '>
        <div className='flex items-center space-x-2'>
          <p className='text-sm font-medium'>Rows</p>
          <Select
            value={`${limit}`}
            onValueChange={(value) => {
              updateLimit(value);
            }}
          >
            <SelectTrigger className='h-8 w-[70px]'>
              <SelectValue placeholder={limit} />
            </SelectTrigger>
            <SelectContent side='top'>
              {[10, 20, 40, 80].map((limit) => (
                <SelectItem key={`select_limit_${limit}`} value={`${limit}`}>
                  {limit}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className='flex h-full flex-1 items-center justify-end pr-2 text-sm font-medium sm:justify-center '>
        Page {currentPage}&nbsp;of&nbsp;{pageCount}
      </div>
      <Pagination className='col-span-2 flex-1 justify-center sm:justify-end '>
        <PaginationContent>
          <PreviousButton offset={offset} pageSize={limit} getPaginatedHref={getPaginatedHref} />
          <PaginationElements
            currentPage={currentPage}
            pageCount={pageCount}
            pageSize={limit}
            getPaginatedHref={getPaginatedHref}
          />
          <NextButton total={total} offset={offset} pageSize={limit} getPaginatedHref={getPaginatedHref} />
        </PaginationContent>
      </Pagination>
    </div>
  );
}
