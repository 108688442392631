'use client';

import { Route } from 'next';
import Image from 'next/image';
import { FC, ReactNode, useState } from 'react';
import { cn } from '../../utils';

import './HoneycombGrid.css';

type HoneycombGridProps = {
  children?: ReactNode;
  className?: string;
};

export const HoneycombGrid: FC<HoneycombGridProps> = ({ children, className }) => {
  return (
    <ul id='hexGrid' className={cn(``, className)}>
      {children}
    </ul>
  );
};

type HoneycombCellProps = {
  selected?: boolean;
  children?: ReactNode;
  href?: Route;
};

export const HoneycombCell: FC<HoneycombCellProps> = ({ children, href }) => {
  const [selected, setSelected] = useState<boolean>(false);

  return (
    <li className='hex'>
      <div className='hexIn'>
        <a
          className={cn('hexLink', { hexLinkOpen: selected, 'cursor-pointer': href && href?.length > 0 })}
          {...(href?.length ? { href } : {})}
          onClick={() => {
            setSelected((s) => !s);
          }}
        >
          {children}
        </a>
      </div>
    </li>
  );
};

type HoneycombTitleProps = {
  children?: ReactNode;
  className?: string;
};

export const HoneyCombTitle: FC<HoneycombTitleProps> = ({ children, className }) => {
  return <h1 className={cn('hexTitle bg-primary/90 text-lg font-semibold text-white', className)}>{children}</h1>;
};

type HoneycombDescriptionProps = {
  children?: ReactNode;
  className?: string;
};

export const HoneyCombDescription: FC<HoneycombDescriptionProps> = ({ children, className }) => {
  return <p className={cn('hexDescription text-md bg-primary/90 font-normal text-white', className)}>{children}</p>;
};

type HoneycombImageProps = {
  className?: string;
  src: string;
  alt: string;
};

export const HoneyCombImage: FC<HoneycombImageProps> = ({ className, src, alt }) => {
  return <Image width={400} height={400} className={cn('hexImg', className)} src={src} alt={alt ?? ''} />;
};
