'use client';

import { FC, SVGProps } from 'react';

export const Logo: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      version='1.1'
      viewBox='0 0 500 329.994'
      {...props}
    >
      <g
        fill='#f5b82e'
        fillOpacity='1'
        stroke='#000'
        strokeDasharray='none'
        strokeOpacity='1'
        strokeWidth='6'
        display='inline'
        transform='translate(-6 -91.003)'
      >
        <path
          d='M173.106 141.337l-46.487 26.84-46.487-26.84V87.66l46.487-26.84 46.487 26.84z'
          transform='matrix(.99527 .57363 -.56887 1.00359 10.621 -39.19)'
        ></path>
        <path
          d='M173.106 141.337l-46.487 26.84-46.487-26.84V87.66l46.487-26.84 46.487 26.84z'
          transform='matrix(.99527 .57363 -.56887 1.00359 10.621 68.486)'
        ></path>
        <path
          d='M173.106 141.337l-46.487 26.84-46.487-26.84V87.66l46.487-26.84 46.487 26.84z'
          transform='matrix(.99527 .57363 -.56887 1.00359 102.861 14.662)'
        ></path>
        <path
          d='M173.106 141.337l-46.487 26.84-46.487-26.84V87.66l46.487-26.84 46.487 26.84z'
          transform='matrix(.99527 .57363 -.56887 1.00359 195.12 68.487)'
        ></path>
        <path
          d='M173.106 141.337l-46.487 26.84-46.487-26.84V87.66l46.487-26.84 46.487 26.84z'
          transform='matrix(.99527 .57363 -.56887 1.00359 287.369 14.662)'
        ></path>
        <path
          d='M173.106 141.337l-46.487 26.84-46.487-26.84V87.66l46.487-26.84 46.487 26.84z'
          transform='matrix(.99527 .57363 -.56887 1.00359 379.609 -39.19)'
        ></path>
        <path
          d='M173.106 141.337l-46.487 26.84-46.487-26.84V87.66l46.487-26.84 46.487 26.84z'
          transform='matrix(.99527 .57363 -.56887 1.00359 379.609 68.487)'
        ></path>
        <path
          d='M173.106 141.337l-46.487 26.84-46.487-26.84V87.66l46.487-26.84 46.487 26.84z'
          transform='matrix(.99527 .57363 -.56887 1.00359 379.609 176.109)'
        ></path>
        <path
          d='M173.106 141.337l-46.487 26.84-46.487-26.84V87.66l46.487-26.84 46.487 26.84z'
          transform='matrix(.99527 .57363 -.56887 1.00359 10.621 176.108)'
        ></path>
      </g>
    </svg>
  );
};
