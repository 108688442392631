import { extendTailwindMerge } from 'tailwind-merge';

// Merge prefixed and non-prefixed class names
// https://github.com/dcastil/tailwind-merge/discussions/296
export const twMerge = extendTailwindMerge((config) => {
  Object.keys(config.classGroups).forEach((key) => {
    const value = config.classGroups[key];
    config.classGroups[key] = [...value, { ui: value }];
  });

  return config;
});
